const questoesAnsiedade = [
    {
        Id: 1,
        Questao: "Tenho me sentindo nervoso(a), ansioso(a) ou muito tenso(a)",
    },
    {
        Id: 2,
        Questao: "Não sou capaz de impedir ou de controlar as minhas preocupações",
    },
    {
        Id: 3,
        Questao: "Me preocupo muito com várias coisas",
    },
    {
        Id: 4,
        Questao: "Tenho dificuldade para descansar a mente",
    },
    {
        Id: 5,
        Questao: "Fico inquieto(a), sendo dificil permanecer sentado(a)",
    },
    {
        Id: 6,
        Questao: "Fico facilmente triste ou irritado(a)",
    },
    {
        Id: 7,
        Questao: "Sinto medo como se algo de ruim fosse acontecer a qualquer momento",
    },
    {
        Id: 8,
        Questao: "Todos esses sintomas têm me causado dificuldade para trabalhar, estudar, tomar conta das coisas em casa ou se relacionar com as pessoas?",
    },
];

export default questoesAnsiedade
