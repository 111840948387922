import { Link } from "react-router-dom";
import Especialidade from "../Especialidade/Especialidade";
import { criaLinkWhatsapp } from "../../services/services";
import { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";


export default function ProfissionalMiniatura(props) {
    const { Id, Url, Nome, Imagem, Celular, Titulo, CRP, Abordagem, Texto, Especialidades } = props.profissional;
    const linkWhatsapp = criaLinkWhatsapp(Celular)
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };


    return (
        <div className='bg-blur w-100 py-4 mb-5'>
            <div className="col-11 mx-auto min-h-miniatura d-flex flex-column justify-content-center">
                <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-stretch text-secondary-emphasis">
                    <div className="col-10 col-sm-7 col-md-6 col-lg-3 col-xxl-2 d-flex flex-column">
                        {!loaded &&
                            <div className="text-center">
                                <AiOutlineLoading color='black' className='load-spinner text-center display-2' />
                            </div>
                        }
                        <img className={`img-fluid rounded ${!loaded ? 'd-none' : ''}`}
                            src={Imagem}
                            alt="Imagem do(a) psicólogo(a)"
                            onLoad={handleImageLoad}
                        ></img>
                        {Titulo && (
                            <div className="text-center fs-5 fw-medium pt-2">{Titulo}</div>)
                        }
                        <Link className="link-offset-2 link-underline link-underline-opacity-0 text-center btn rounded-1 fs-6 fst-italic  text-secondary-emphasis bg-danger-subtle fw-semibold rounded mt-1" to={`/profissional/${Url}`}>Mais informações</Link>
                    </div>
                    <div className="mx-md-4 mt-3 mt-lg-0 col-12 col-sm-11 col-md-10 col-lg-9 col-xxl-10">
                        <p className="fs-5 fw-bold my-0">{Nome} | CRP {CRP}</p>
                        <p className="fs-5 fw-medium my-0 text-danger-emphasis"><span className="fst-italic">{Abordagem.join(', ')}</span></p>
                        <p className="fs-5 fw-medium mb-0 mt-3 text-start text-lg-start" dangerouslySetInnerHTML={{ __html: Texto }}></p>
                        <div className="d-flex mt-2 mt-lg-4">
                            <div className="d-flex my-2 ms-auto flex-wrap">
                                {Especialidades.map((especialidade, index) => (
                                    <Especialidade especialidade={especialidade} key={index} />
                                ))}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            {/* <Link className="d-none d-sm-block btn btn-lg fw-bold rounded-1 btn-bege me-2" to={`/profissional/${Url}`}>Mais informações</Link> */}
                            <Link className="d-none d-sm-block btn btn-lg fw-bold rounded-1 btn-outline-success" to={linkWhatsapp} target="_blank">Agendar sessão <FaWhatsapp className="fs-3 mb-1" /></Link>
                        </div>
                        <div className="d-flex flex-column">                            
                            <Link className="d-block d-sm-none w-100 btn btn-lg ms-auto fw-bold rounded-1 btn-bege" to={linkWhatsapp} target="_blank">Agendar sessão</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
