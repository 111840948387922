import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Index from './pages/Index/Index'
import Profissional from './pages/Profissional/Profissional';
import Profissionais from './pages/Profissionais/Profissionais';
import Quiz from './pages/Quiz/Quiz';
import AtendimentoOnline from './pages/AtendimentoOnline/AtendimentoOnline';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/" element={<Index />} />            
            <Route path="/atendimento-online" element={<AtendimentoOnline />} />            
            <Route path="/profissionais" element={<Profissionais />} />            
            <Route path="/quiz/:condicao" element={<Quiz />} />            
            {/* <Route path="/profissionais?especialidade=:especialidade" element={<Profissionais />} /> */}
            {/* <Route path="/profissionais/:especialidade/:abordagem" element={<Profissionais />} /> */}
            <Route path="/profissional/:url" element={<Profissional />} />
        </Routes>
    )
}