export default function LivroSugerido(props) {
    return (
        <>
            <div className="d-flex mb-4 gap-3">
                <div className='col-4 col-sm-2'>
                    <img src={props.imagem} className='img-fluid'></img>
                </div>
                <div className='d-flex flex-column col-8'>
                    <p className='fs-5 fst-italic mt-0 mb-2'>{props.titulo}</p>
                    <p className='text-warning-emphasis fw-bold my-0'>{props.autor}</p>
                </div>
            </div>
        </>
    )
}