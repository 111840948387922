import Navbar from './components/Navbar/Navbar';
import './style.css'
import AppRoutes from './routes';

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
