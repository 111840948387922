import { Link } from "react-router-dom"
import { BsInstagram } from "react-icons/bs"

export default function Footer() {
    var anoAtual = new Date().getFullYear()
    return (
        <>
            <div className="border-top border-secondary py-2 px-4">
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <div className="d-flex gap-2 justify-content-center align-items-center fs-6">
                        <Link className="link-offset-2 link-underline link-underline-opacity-0 text-body-emphasis"
                        target="_blank"
                        to="https://www.instagram.com/falarsalva.ong/">
                            <BsInstagram className="" size="1.3rem" />
                        </Link>
                        <Link className="link-offset-2 link-underline link-underline-opacity-0 text-body-emphasis mt-1"
                        target="_blank"
                        to="https://www.instagram.com/falarsalva.ong/">falarsalva.ong
                        
                        </Link>
                    </div>
                    <p className="my-2 my-sm-0 text-body-emphasis fs-6 fw-medium"> © {anoAtual} Falar Salva</p>
                    <div className="d-flex flex-column align-items-center align-items-sm-end lh-sm footer-dev">
                        <p className="my-0">Desenvolvido por <Link className="link-offset-2 link-underline link-underline-opacity-0 text-danger-emphasis" target="_blank" to="https://matheusg.vercel.app/">Matheus G</Link>
                        </p>
                        <p className=" my-0">
                            <Link className="link-offset-2 link-underline link-underline-opacity-0 text-danger-emphasis align-self-end" target="_blank" to="https://portfolio-jeangs8.vercel.app/">Jean Gomes</Link></p>
                    </div>
                </div>
            </div>

        </>
        // <div className="mt-auto d-flex flex-column align-items-center justify-content-center text-secondary border-top border-secondary fs-6 py-2 footer">
        //     <p className="my-0"> © {anoAtual} Falar Salva</p>
        //     <p className="my-0">Desenvolvido por <Link className="link-offset-2 link-underline link-underline-opacity-0 text-danger-emphasis" target="_blank" to="https://matheusg.vercel.app/">Matheus G</Link> e
        //     <Link className="link-offset-2 link-underline link-underline-opacity-0 text-danger-emphasis" target="_blank" to="https://portfolio-jeangs8.vercel.app/"> Jean Gomes</Link></p>
        // </div>
    )
}