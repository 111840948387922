import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ilustracaoTerapia from '../../img/ilustracao-terapia.png'
import ilustracaoTerapiaOnline from '../../img/ilustracao-terapia-online.png'
import ilustracaoConversa from '../../img/ilustracao-conversa.png'

import { BsPinAngle } from 'react-icons/bs'
import { Bs1Circle, Bs2Circle, Bs3Circle, Bs4Circle } from "react-icons/bs";
import { AiOutlinePushpin, AiOutlineMessage, AiOutlineUser } from 'react-icons/ai'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'
import { FaUserFriends, FaUserAlt } from 'react-icons/fa'
import { MdComputer } from 'react-icons/md'
import { Link } from "react-router-dom";
import Prefooter from "../../components/Footer/Prefooter";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import { useEffect } from "react";

export default function AtendimentoOnline() {
    const tamanhoIcone = "5rem";
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="min-vh-100 d-flex flex-column">
                <Navbar classNameTitulo="text-danger-emphasis" classNameItem="text-danger-emphasis" />
                <div className='col-xl-8 col-xxl-6 mx-auto'>
                    <h1 className='display-4 my-3 text-center text-lg-start text-secondary-emphasis fw-medium'>Atendimento online</h1>
                </div>
                {/* <div className='bg-encontre-psi'>
                    <div className='ms-lg-5 ps-lg-2'>
                        <h1 className='display-2 my-3 my-md-4 text-center text-lg-start text-secondary-emphasis fw-medium'>Atendimento online</h1>
                    </div>
                    <div className='col-12 d-flex flex-column align-items-center mb-4 pt-3 pt-md-5  pb-md-4'>
                        <div className="mb-2 col-11 col-lg-10 col-xl-8 col-xxl-6 px-4 py-2 rounded-1 form-encontre-psi">
                            <div className='fs-3 mb-2 fw-semibold text-center text-md-start '>Encontre seu psicólogo</div>
                            <form id="formBuscaPsicologo" method="get">
                                <div className='col-12 col-md-11 col-xxl-11'>
                                    <label htmlFor="nome" className="form-label fw-semibold fs-5 mb-1">Buscar por nome</label>
                                    <input className="form-control form-control mb-3" type="text" placeholder="Nome" id="nome" name="nome" />
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-3 align-items-md-end'>
                                    <div className='col-12 col-md-4 col-xxl-4'>
                                        <label htmlFor="especialidade" className="form-label fw-semibold fs-5 ">Buscar por especialidade</label>
                                        <select className="form-select form-select" id="especialidade" name="especialidade">
                                            <option selected disabled value="">Buscar</option>
                                        </select>
                                        <div className='d-flex'>
                                            <p className='fs-6 text-danger ms-auto limpar-select'>Limpar</p>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-4 col-xxl-4'>
                                        <label htmlFor="abordagem" className="form-label fw-semibold fs-5">Buscar por abordagem</label>
                                        <select className="form-select form-select" id="abordagem" name="abordagem">
                                            <option selected disabled value="">Buscar</option>

                                        </select>
                                        <div className='d-flex'>
                                            <p className='fs-6 text-danger ms-auto limpar-select'>Limpar</p>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn col-12 col-sm-3 align-self-center">Buscar</button>
                                </div>
                            </form>
                        </div>
                    </div> 
                </div> */}
                <div className="col-xl-8 col-xxl-6  mx-auto d-flex">
                    <div className="fs-5 text-body-emphasis fw-normal mx-3 mx-md-0">
                        <p className="pip3 my-2 mx-0">
                            Em 2018, o CFP (Conselho Federal de Psicologia), órgão que regulamenta a profissão de psicólogo no Brasil, publicou a Resolução CFP nº 011/18, que autorizou as consultas psicológicas on line.
                        </p>
                        <img src={ilustracaoTerapiaOnline} alt="Ilustração de duas mulheres conversando, uma delas sentada numa cadeira e outra sendo mostrada através de uma tela" className="img-fluid float-end col-5" id="img-atendimento-online-1" />
                        <p className="pip3 my-2 mx-0">Essa resolução orienta e estabelece normas que devem ser seguidas pelos profissionais para a garantia de que o atendimento on line obedeça às diretrizes éticas do Código de Ética do psicólogo.</p>
                        <p className="pip3 my-2 mx-0">Os psicólogos precisam seguir as mesmas condutas que possuem nos consultórios em atendimentos presenciais como sigilo, um ambiente adequado e tranquilo sem a interferência de terceiros.</p>
                        <p className="pip3 my-2 mx-0">As consultas e/ou atendimentos psicológicos podem ser realizados em tempo real (atendimento síncrono) ou de forma assíncrona (mensagens, ligações telefônicas, entre outros), nas diferentes áreas de atuação da Psicologia com vistas à avaliação, orientação e/ou intervenção em processos individuais e grupais.</p>
                        <p className="pip3 my-2 mx-0">Com o início da pandemia de COVID-19 e o afastamento social ocorrido em 2020, essa modalidade de atendimento se tornou mais popular entre os profissionais e os cidadãos, pois foi uma forma de continuar o acompanhamento respeitando o distanciamento social imposto pela pandemia.</p>

                        <p className="pip3 my-2 mx-0">Os consultórios virtuais estão se tornando cada vez mais popular e a aceitação do público também, que pode ser explicado pelo fato do cliente ter o atendimento na comodidade de seu lar ou de onde estiver, sendo em viagem, durante o horário de almoço do trabalho ou até mesmo em outro país.</p>

                        <p className="pip3 my-2 mx-0">A eficácia desse novo modelo de atendimento vem se solidificando à medida em que percebe-se que a tecnologia utilizada é apenas um meio, que é amplamente utilizado por quase toda a população, para realização de um trabalho sem perdas na sua qualidade.</p>

                        <p className="pip3 my-2 mx-0">Utilizam-se vários aplicativos e plataformas populares de comunicação como Whatsapp, Telegram, Zoom, Skype e Google Meet, dentre outros para as sessões. Estes viabilizam a videoconferência que se torna muito semelhante àquela vivenciada em atendimentos presenciais, pois permitem o diálogo em tempo real por meio de contato visual e por voz.</p>

                        <h2 className="pip3 mt-5 mb-4 mx-0 fw-semibold fs-3">Existem várias vantagens nos atendimentos on line e podemos citar as seguintes:</h2>
                        <ul className="fst-italic fw-medium mb-4">
                            <li className="">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />Maior facilidade para se expressar por estar em um local de sua preferência. Na maioria das vezes em casa;
                            </li>
                            <li className=" ">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />Pessoas com limitações físicas podem ser atendidas de onde estiverem;
                            </li>
                            <li className=" ">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />Privacidade: você não se expõe nas salas de espera de consultórios;
                            </li>
                            <li className=" ">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />Menos gasto com deslocamento e evita-se o estresse de trânsito até o consultório;
                            </li>
                            <li className=" ">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />Valor reduzido da consulta;
                            </li>
                            <li className=" ">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />Maior disponibilidade e flexibilidade de horários dos profissionais;
                            </li>
                            <li className=" ">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />O profissional não precisa ser de sua cidade;
                            </li>
                            <li className="">
                                <BsPinAngle color="#" className="ms-1 me-1 my-2 text-danger-emphasis" />Pode-se viajar ou mudar de cidade ou até de país sem se preocupar em mudar de profissional, entre outras.
                            </li>
                        </ul>
                        <img src={ilustracaoConversa} alt="Ilustração de duas pessoas dentro de dois balões de conversa" className="img-fluid float-end col-5"></img>
                        <p className="pip3 my-2 mx-0">
                            O atendimento on line é tão eficaz quanto o presencial por utilizar das tecnologias de informação e da comunicação (TICs) que, geralmente, possuem intimidade com o usuário, visto que grande parte da população hoje utiliza pelo menos do Whatsapp para se comunicar. O usuário precisará de internet, smartphone, computador ou tablet, fones de ouvido e um ambiente tranquilo para realizar esse atendimento/consulta.
                        </p>
                        <p className="pip3 my-2 mx-0">
                            Seja de onde o psicólogo ou o cliente estejam, essa nova forma de atendimento facilita o processo psicoterapêutico sem perdas na qualidade, interrupções ou desistências no tratamento devido às dificuldades em estar presente num consultório físico.
                        </p>
                    </div>
                </div>
                <Disclaimer className="mt-5" />
                <div className="footer">
                    <Prefooter />
                    <Footer />
                </div>
            </div>
        </>
    )
}