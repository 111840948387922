import { BsInstagram } from "react-icons/bs"
import { Link } from "react-router-dom"
import logo from '../../img/falarSalvaLogoG.png'

export default function Prefooter() {
    var anoAtual = new Date().getFullYear();
    return (
        <>

            <div className="d-flex flex-column flex-md-row justify-content-between px-4 pt-2 pb-1">
                <div className="col-5 col-md-3 col-lg-2 col-xl-2 d-flex align-items-center justify-content-center justify-content-md-start py-3 py-sm-0 my-2 mx-auto mx-lg-2">
                    <img src={logo} className="img-fluid col-sm-8 col-md-12 col-lg-8 col-xl-7"></img>
                </div>
                <div className="d-flex flex-column flex-lg-row justify-content-end gap-0 gap-sm-3 gap-md-4 gap-lg-4">                    
                    <div className="d-flex flex-column mb-3">
                        <p className="mt-0 mb-1 fs-5 fw-semibold">Quiz</p>
                        <Link
                            className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6 fw-normal"
                            to="/quiz/Ansiedade">Ansiedade
                        </Link>
                        <Link
                            className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6 fw-normal"
                            to="/quiz/Depressão">Depressão
                        </Link>
                    </div>
                    <div className="d-flex flex-column mb-3">
                        <p className="mt-0 mb-1 fs-5 fw-semibold">Plataforma</p>
                        <Link
                            className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6 fw-normal"
                            to="/atendimento-online">Atendimento online</Link>
                        <Link
                            className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6 fw-normal"
                            to="https://drive.google.com/file/d/1UUNmq7xFW3l8fNPxSDy7dHpnDERadcHs/view?usp=sharing"
                            target="_blank">Resolução CFP Nº 11/2018</Link>
                            {/* <Link
                            className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6 fw-normal"
                            to="">Dar feedback</Link> */}
                    </div>
                    <div className="d-flex flex-column mb-3">
                        <p className="mt-0 mb-1 fs-5 fw-semibold">Encontre Psicólogos por Especialidade</p>
                        <div className="d-flex gap-4 fw-normal">
                            <div className="d-flex flex-column align-self-start">
                                <Link
                                    className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6"
                                    to="/profissionais?especialidade=Ansiedade">Ansiedade</Link>
                                <Link
                                    className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6"
                                    to="/profissionais?especialidade=Depressão">Depressão</Link>
                                <Link
                                    className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6"
                                    to="/profissionais?especialidade=Autoconhecimento">Autoconhecimento</Link>
                            </div>
                            <div className="d-flex flex-column">
                                <Link
                                    className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6"
                                    to="/profissionais?especialidade=Alterações de humor">Alterações de humor</Link>
                                <Link
                                    className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6"
                                    to="/profissionais?especialidade=Autoestima">Autoestima</Link>
                                <Link
                                    className="link-offset-2 link-underline link-underline-opacity-0 text-dark fs-6 fw-medium"
                                    to="/profissionais">Ver mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}