const avaliacoes = [
  /*
  {
    estrela: "5.0",
    texto: "Sempre encontro orientação e sabedoria para lidar com as complexidades da vida com essa abordagem filosófica única. As discussões mentais e espirituais são um bônus incrível. [Avaliação fictícia]",
    autor: "Mário P. - Artista",
    profissionalId: 1 
  },
  {
    estrela: "4.5",
    texto: "Ela me ajuda a ter clareza em questões de trabalho, relacionamento, auto conhecimento, entre outros, sempre com uma abordagem filosófica e aprofundando em questões mentais e espirituais. Gratidão [Avaliação fictícia]",
    autor: "Marcia J. - Dona de casa",
    profissionalId: 1 
  },
  {
    estrela: "4.5",
    texto: "Receber orientação sobre assuntos profissionais e pessoais, mergulhando em questões mais profundas, tem sido uma jornada esclarecedora. Obrigado! [Avaliação fictícia]",
    autor: "Paulo - Aux. administrativo",
    profissionalId: 2 
  },
  {
    estrela: "5.0",
    texto: "É incrível como as conversas filosóficas e introspectivas me ajudam a compreender melhor a mim mesmo e a tomar decisões mais conscientes. [Avaliação fictícia]",
    autor: "Maria - Juíza",
    profissionalId: 3 
  },
  {
    estrela: "5.0",
    texto: "A abordagem filosófica traz uma perspectiva única para questões de relacionamento e autoconhecimento. Estou grato por isso. [Avaliação fictícia]",
    autor: "Jonas - Médico",
    profissionalId: 2
  },
  {
    estrela: "5.0",
    texto: "A ajuda recebida para navegar pelas águas turbulentas do trabalho e das questões pessoais tem sido inestimável. A abordagem filosófica é revigorante. [Avaliação fictícia]",
    autor: "Mario - Atendente",
    profissionalId: 3 
  },
  {
    estrela: "5.0",
    texto: "Encontrar respostas para as questões mais profundas da vida, de trabalho a relacionamentos, tem sido uma jornada enriquecedora. [Avaliação fictícia]",
    autor: "Arthur - Encanador",
    profissionalId: 5 
  },
  {
    estrela: "4.5",
    texto: "As discussões filosóficas e introspectivas têm sido um farol em meu caminho para um maior autoconhecimento. Grato por isso. [Avaliação fictícia]",
    autor: "Jorge - Auxiliar",
    profissionalId: 4
  },
  {
    estrela: "4.5",
    texto: "Quisque efficitur, metus quis viverra ullamcorper, neque lacus feugiat felis.",
    autor: "John - Atendente",
    profissionalId: 4 
  } 
   */
]

export default avaliacoes;