import imgJessicaRubatino from '../img/slider/jessica-janis-rubatino.jpg'
import imgBrendaHellen from '../img/slider/brenda-hellen-silva.jpg'
import imgGicieleGomes from '../img/slider/giciele-gomes.jpg'
import imgJulianaBruno from '../img/slider/juliana-bruno-silva.jpg'
import imgAdrianaOttone from '../img/slider/adriana-ottone-veiga.jpeg'
import imgDayaneMendes from '../img/slider/dayane-mendes.jpg'
import imgLorenaSouza from '../img/slider/lorena-souza-farias.jpg'
import imgJulianaKeide from '../img/slider/juliana-keide-ferreira.jpg'
import imgNayron from '../img/slider/nayron-ferreira-mariosa.png'
import imgCarlosFelippe from '../img/slider/carlos-felippe-cordeiro.jpg'
import imgDanielMadeira from '../img/slider/daniel-madeira.png'
import imgIsabelaRocha from '../img/slider/isabela-rocha.jpg'

const profissionais = [
    // {
    //     Id: 1,
    //     Nome: "Jéssica Janis Rubatino Aleixo Mariosa Antunes",
    //     Url: "jessica-janis-rubatino",
    //     Imagem: imgJessicaRubatino,
    //     Titulo: "Fundadora Falar Salva",
    //     CRP: "04/64738",
    //     Abordagem: ["Terapia Cognitivo Comportamental", "Terapia Comportamental Dialética"],
    //     Texto: "A psicóloga clínica Jéssica Rubatino é fundadora do projeto social Falar Salva, formada em Psicopatologia e Emagrecimento, especialista em Terapia Cognitivo Comportamental e formação em Terapia Comportamental Dialética. Com experiência nos transtornos de ansiedade, transtorno depressivo, transtorno de personalidade dependente e boderline, transtorno de humor e transtornos alimentares.",
    //     Celular: "553195133688",
    //     Especialidades: ["Adolescente", "Adulto", "Autoconhecimento", "Bariátrica", "Brasileiros no exterior", "Burnout", "Casal", "Compulsão alimentar", "Conflitos familiares", "Desenvolvimento profissional", "Relacionamentos", "TDAH"]
    // },
    {
        Id: 2,
        Nome: "Brenda Hellen da Silva Sales",
        Url: "brenda-hellen-silva",
        Imagem: imgBrendaHellen,
        Titulo: "Cofundadora Falar Salva",
        CRP: "04/69550",
        Abordagem: ["Fenomenológica-existencial"],
        Texto: "Seus atendimentos são voltados para adolescentes e adultos. Possui experiência em processos grupais, atendimento a familiares e usuários de álcool e outras drogas, orientação profissional e psicologia social. Em sua prática clínica, Brenda realiza atendimentos voltados para pessoas em processo de luto, que tiverem perdas concretas pela morte de um ente querido, ou pela perda simbólica (fim de relacionamento, mudança de cidade ou emprego e descoberta de uma doença, por exemplo). Brenda acredita na psicologia como uma importante via de reflexões sobre o próprio existir a partir da construção de novos sentidos e possibilidades para as experiências de dor e adoecimento.",
        Celular: "5531984286191",
        Especialidades: ["Adolescente", "Adulto", "Luto", "Relacionamentos", "Cuidados paliativos", "Autoconhecimento", "Atendimento familiar", "Orientação profissional", "Dependência química"]
    },
    {
        Id: 3,
        Nome: "Giciele Gomes",
        Url: "giciele-gomes",
        Imagem: imgGicieleGomes,
        Titulo: "Cofundadora Falar Salva",
        CRP: "04/55931",
        Abordagem: ["Terapia Cognitivo Comportamental"],
        Texto: "Psicóloga clínica, com formação em psicopatologia, TCC e depressões resistentes. Giciele tem vasta experiência no atendimento a mulheres em situação de violências e relacionamentos abusivos. Trabalho voltado para retomada de autonomia, em um ambiente de acolhimento e segurança.",
        Celular: "553185747054",
        Especialidades: ["Adulto", "Gestante", "Apoio a mulheres", "TDAH", "Relacionamentos abusivos","Autoconhecimento","Burnout", "Depressão", "Ansiedade"]
    },
    {
        Id: 4,
        Nome: "Juliana Bruno da Silva",
        Url: "juliana-bruno-silva",
        Imagem: imgJulianaBruno,
        Titulo: "Cofundadora Falar Salva",
        CRP: "04/65574",
        Abordagem: ["Terapia Sistêmica"],
        Texto: "Psicóloga clínica especialista em terapia sistêmica, para o público jovem, adultos e 3ª idade. Juliana realiza atendimentos para transtornos de ansiedade, transtornos depressivos, burnout, conflitos familiares e pacientes em tratamento de câncer. Através da terapia sistêmica há uma exploração individual do paciente dentro do seu complexo familiar.",
        Celular: "5531992981501",
        Especialidades: ["Jovem", "Adulto", "Idoso", "Ansiedade", "Depressão","Burnout", "Paciente oncológico", "Conflitos familiares", "Fibromialgia"]
    },
    {
        Id: 5,
        Nome: "Adriana K. Ottone Veiga",
        Url: "adriana-ottone-veiga",
        Imagem: imgAdrianaOttone,
        Titulo: "Cofundadora Falar Salva",
        CRP: "04/47485",
        Abordagem: ["Clínica Psicanalítica"],
        Texto: "Psicóloga clínica com formação em Psicanálise; especialista em Saúde Mental e Atenção Psicossocial; capacitação em Tanatologia, voltado ao desenvolvimento de recursos terapêuticos para trabalhar mudanças e perdas. Pós graduanda em Psicologia Clínica. Adriana possui experiência na atuação em promoção da saúde com foco em idosos crônicos, gestantes e casos graves em saúde mental. A psicóloga é encantada com a multiplicidade das experiências de vida e da singularidade de cada sujeito, aliado às diversas ferramentas que a Psicologia proporciona para vivenciarmos os mais variados enfrentamentos existenciais.",
        Celular: "553291203421",
        Especialidades: ["Adulto", "Idoso", "Gestantes", "Luto", "Autoconhecimento","Depressão", "Relacionamentos", "Dependência emocional", "Envelhecimento"]
    },
    // {
    //     Id: 6,
    //     Nome: "Dayane Mendes",
    //     Url: "dayane-mendes",
    //     Imagem: imgDayaneMendes,
    //     Titulo: "Cofundadora Falar Salva",
    //     CRP: "24/03979",
    //     Abordagem: ["Psicóloga Comportamental"],
    //     Texto: "Psicóloga clínica e terapeuta ABA. Especialista em Análise do Comportamento Aplicada. A psicoterapia é uma jornada de autoconhecimento e desenvolvimento pessoal. Se você busca um espaço seguro para explorar seus sentimentos, lidar com desafios e desenvolver habilidades, estou aqui para te acompanhar nesse processo.",
    //     Celular: "5569993760311",
    //     Especialidades: ["Jovens adultos de 18 Anos até 35 anos", "Ansiedade", "Relacionamentos", "Autoconhecimento"]
    // },
    {
        Id: 7,
        Nome: "Lorena de Souza Farias",
        Url: "lorena-souza-farias",
        Imagem: imgLorenaSouza,
        Titulo: "Cofundadora Falar Salva",
        CRP: "04/59612",
        Abordagem: ["Terapia Cognitivo Comportamental"],
        Texto: "Psicóloga clínica, com abordagem baseada em evidências científicas para melhorar a qualidade de vida das pessoas. Lorena tem experiência em atendimento a pessoas com transtornos de ansiedade (fobias, ansiedade social, pânico, agorafobia e etc) e transtornos depressivos. Acredita que a psicologia pode ajudar pessoas para além dos transtornos mentais, por isso também atende aqueles que querem melhorar a qualidade de suas relações, desenvolvendo habilidades para lidar melhor com suas emoções e ter uma vida com mais leveza.",
        Celular: "553196204599",
        Especialidades: ["Adolescente", "Adulto", "Idoso", "Ansiedade", "Depressão", "Autoconhecimento", "Regulação emocional", "Fibromialgia", "Relacionamentos"]
    },
    {
        Id: 8,
        Nome: "Nayron Ferreira Mariosa",
        Url: "nayron-ferreira-mariosa",
        Imagem: imgNayron,
        Titulo: "Cofundador Falar Salva",
        CRP: "04/58381",
        Abordagem: ["Terapia Cognitivo Comportamental"],
        Texto: "Psicólogo especialista em neuropsicologia e terapia cognitivo comportamental, Psicologia em Cuidados Paliativos e Psicologia Jurídica. Com experiência em atendimentos com crianças acima de 10 anos, adolescentes e adultos. Nayron utiliza a TCC nos cuidados de pacientes com transtornos depressivos, transtornos de personalidade borderline, transtorno bipolar, transtornos de ansiedade, transtornos alimentares, dependentes químicos e aqueles que estão em processo de cuidados paliativos.",
        Celular: "553199286319",
        Especialidades: ["Adolescente","Adulto", "Idoso", "Ansiedade", "Depressão", "TDAH", "Alterações de humor", "Burnout", "Transtorno alimentar"]
    },
    {   
        Id: 9,     
        Nome: "Isabela Rocha",
        Url: "isabela-rocha",
        Imagem: imgIsabelaRocha,
        Titulo: "Cofundadora Falar Salva",
        CRP: "04/73943",
        Abordagem: ["Terapia Cognitivo Comportamental"],
        Texto: "Atendimentos para adolescentes, adultos e idosos em busca de autoconhecimento e melhor qualidade de vida. Especialista em Terapia Cognitivo Comportamental e formação em obesidade e emagrecimento. Possui experiência em atendimento à vítimas de violência e vitimas de tentativa de feminicídio. Realiza trabalho cognitivo com pessoas idosas.",
        Celular: "553188743194",
        Especialidades: ["Adolescente", "Adulto", "Idoso", "Autoconhecimento", "Vítimas de violência", "Trabalho cognitivo com idosos", "Ansiedade", "Burnout", "Depressão", "TDAH"]
    },
    {
        Id: 10,     
        Nome: "Daniel Madeira",
        Url: "daniel-madeira",
        Imagem: imgDanielMadeira,
        Titulo: "Cofundador Falar Salva",
        CRP: "04/69106",
        Abordagem: ["Terapia Cognitiva Comportamental"],
        Texto: `<p class='fw-bold pt-2'>Saúde mental do homem</p>
        <p>A rotina de um homem pode ser muito desafiadora. Pressões no trabalho, responsabilidades com a família, finanças e a busca constante para manter tudo em ordem. E embora ele seja aquele que sempre oferece força e apoio, quem cuida dele?</p>

<p>O cuidado emocional é tão importante quanto o físico. Ter um espaço para falar tudo que se pensa e sente, desabafar e ter o auxílio de um profissional é o primeiro passo para viver menos tenso e sobrecarregado.</p>

<p>Se você é um homem que sente o peso da responsabilidade, ou uma esposa que percebe que seu marido precisa de um tempo para ele, conte com a ajuda de um psicólogo que estuda e vivencia os desafios do universo masculino.</p>

<p>Nosso atendimento é focado em entender as dificuldades que os homens enfrentam na atualidade, e ajudá-los a superar esses obstáculos, reorganizar às coisas, melhorar o humor e diminuir a pressão interna.</p>

<p>Através de atendimentos sigilosos, oferecemos o suporte que o homem precisa para se sentir mais confiante, seguro emocionalmente e aliviado, e assim exercer o seu papel da melhor forma.</p>

<p>Talvez você pense que terapia não é "coisa de homem", ou "não vou falar minha vida pra um estranho", mas eu te convido a fazer só um teste, faça só 1 mês de atendimentos e avalie os resultados que terá nesse curto período. Se não gostar do resultado, é só não renovar seu atendimento. Sem complicação.</p>

<p>Se você é uma esposa e quer apoiar o seu marido, dê esse presente à ele. Esse passo poderá ajudá-lo, ajudar o casamento de vocês e a sua família.</p>

<p>O homem precisa ter paz dentro de si, para ir pra guerra e matar um leão todos os dias.</p>

<p>Muito prazer, sou Daniel, psicólogo clínico graduado pela Faculdade Ciências Médicas MG, terapeuta cognitivo comportamental e ajudo homens todos os dias a viverem melhor, melhorarem seus relacionamentos e se desenvolverem.</p>

<p>Entre em contato hoje e descubra como a psicoterapia pode transformar a sua vida ou a vida de quem você ama.</p>`,
        Celular: "553187899654",
        Especialidades: ["Alterações de humor", "Ansiedade", "Autoestima", "Assertividade", "Casamento", "Conflitos familiares", "Desenvolvimento pessoal", "Dependência emocional", "Inteligência emocional", "Saúde mental"]
    }
];

export default profissionais
