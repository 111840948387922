import { useEffect } from "react";
import { useState } from "react";
import {BsStarFill, BsStarHalf, BsStar} from "react-icons/bs"

export default function BalaoAvaliacao(props) {
    const {estrela, texto, autor} = props.avaliacao;

    const [star, setStar] = useState({
        cheia: 0,
        metade: 0,
        vazia: 0
    });

    useEffect(() => {
        const cheia = Math.floor(estrela);
        const metade = estrela - cheia > 0 ? 1 : 0;
        const vazia = 5 - cheia - metade;

        setStar(prevStar=> ({...prevStar, cheia, metade, vazia}));

    }, [estrela]);
    
    const estrelaCheia = Array.from({ length: star.cheia }, (_, index) => ( <BsStarFill key={index} color="yellow" className="m-1" />));
    
    const estrelaMetade = Array.from({ length: star.metade }, (_, index) => ( <BsStarHalf key={index} color="yellow" className="m-1" />));

    const estrelaVazia = Array.from({ length: star.vazia }, (_, index) => ( <BsStar key={index} color="yellow" className="m-1" />));

    return (
        <>
            <div className="col-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
                <blockquote className="speech-bubble text-secondary-emphasis">
                    <p className="fs-3 text-center">
                        {estrelaCheia}
                        {estrelaMetade}
                        {estrelaVazia}
                    </p>
                    <p className="fs-6 fw-medium">{texto}</p>
                    <p className="text-secondary-emphasis fs-6 m-0 text-end">{autor}</p>
                    <p className="text-secondary fs-6 m-0 text-end">Junho, 2023</p>
                </blockquote>
            </div>
        </>
    )
}