const questoesDepressao = [
    {
        Id: 1,
        Questao: "Pouco interesse ou pouco prazer em fazer as coisas",
    },
    {
        Id: 2,
        Questao: "Se sentir para baixo, deprimido(a) ou sem perspectiva",
    },
    {
        Id: 3,
        Questao: "Dificuldade para pegar no sono ou permanecer dormindo, ou dormir mais do que de costume",
    },
    {
        Id: 4,
        Questao: "Se sentir cansado(a) ou com pouca energia",
    },
    {
        Id: 5,
        Questao: "Falta de apetite ou comendo demais",
    },
    {
        Id: 6,
        Questao: "Se sentir mal consigo mesmo(a), achar que é um fracasso ou que decepcionou sua família",
    },
    {
        Id: 7,
        Questao: "Dificuldade para se concentrar nas coisas, como ler ou assistir algo",
    },
    {
        Id: 8,
        Questao: "Lentidão (para se movimentar ou falar) ou inquietação (ficar andando de um lado para o outro)",
    },
    {
        Id: 9,
        Questao: "Pensar em se ferir de alguma maneira ou que seria melhor estar morto(a)",
    },
    {
        Id: 10,
        Questao: "O quanto esses sintomas têm causado dificuldade para trabalhar, estudar, tomar conta das coisas em casa ou se relacionar com as pessoas?",
    },
];

export default questoesDepressao
