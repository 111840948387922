import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ilustracaoTerapia from '../../img/ilustracao-terapia.png'

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { BsPinAngle } from 'react-icons/bs'
import { Bs1Circle, Bs2Circle, Bs3Circle, Bs4Circle } from "react-icons/bs";
import { AiOutlinePushpin, AiOutlineMessage, AiOutlineUser } from 'react-icons/ai'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'
import { FaUserFriends, FaUserAlt } from 'react-icons/fa'
import { MdComputer } from 'react-icons/md'
import { Link } from "react-router-dom";
import Prefooter from "../../components/Footer/Prefooter";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import { useEffect, useRef } from "react";


export default function Index() {
    const carouselMobileRef = useRef(null);
    const carouselDesktopRef = useRef(null);
    const nextMobileRef = useRef(null);
    const nextDesktopRef = useRef(null);
    const tamanhoIcone = "5rem";
    
    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
                if(nextDesktopRef.current && nextMobileRef.current){
                    nextMobileRef.current.click();
                    nextDesktopRef.current.click();
                }
            }, 4000)
    }, []);

    return (
        <>
            <div className="min-vh-100 d-flex flex-column" id="main">
                <Navbar classNameTitulo="text-white sombra-texto" classNameItem="text-white sombra-texto" />
                {/* Telas menores */}
                <div className="d-sm-none d-flex flex-column flex-wrap text-danger-emphasis flex-fill justify-content-between" >
                    <Link to="/profissionais" className="fs-4 fw-semibold link-underline link-underline-opacity-0 border-0 rounded-start-4 py-2 px-3 my-0 mt-5 text-danger-emphasis btn-agendar-consulta ms-auto">
                        Agende agora sua consulta
                    </Link>
                    <div id="carouselMobile" className="carousel slide" data-bs-ride="carousel" data-bs-theme="dark" ref={carouselMobileRef}>
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="4000">
                                <p className="texto-inicio-carousel my-0 fs-5 px-3 my-0 fw-medium col-12" >
                                    Falar Salva é um projeto social que visa proporcionar atendimento psicológico online de acordo com as condições socioeconômicas de cada pessoa.
                                </p>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <p className="texto-inicio-carousel my-0 fs-5 px-3 my-0 fw-medium">
                                    Nós acreditamos que a Terapia é para todos aqueles que estejam dispostos a enfrentarem as suas batalhas internas, independente da condição socioeconômica de cada um, e com ajuda especializada.
                                </p>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselMobile" data-bs-slide="prev">
                            <IoIosArrowBack className="text-dark rounded-circle bg-branco-transparente" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next bg-btn-mobile" type="button" data-bs-target="#carouselMobile" data-bs-slide="next" ref={nextMobileRef}>
                            <IoIosArrowForward className="text-dark rounded-circle bg-branco-transparente" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="d-none d-sm-flex flex-column flex-wrap text-danger-emphasis flex-fill justify-content-end" >
                    <div id="carouselMd" className="carousel slide" data-bs-ride="carousel" data-bs-theme="dark" ref={carouselDesktopRef}>
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="4000">
                                <p className="texto-inicio-carousel my-0 my-0 fw-medium col-12" >
                                    Falar Salva é um projeto social que visa proporcionar atendimento psicológico online de acordo com as condições socioeconômicas de cada pessoa.
                                </p>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <p className="texto-inicio-carousel my-0 my-0 fw-medium">
                                    Nós acreditamos que a Terapia é para todos aqueles que estejam dispostos a enfrentarem as suas batalhas internas, independente da condição socioeconômica de cada um, e com ajuda especializada.
                                </p>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselMd" data-bs-slide="prev">
                            <IoIosArrowBack className="text-dark rounded-circle bg-branco-transparente" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next bg-btn-mobile" type="button" data-bs-target="#carouselMd" data-bs-slide="next" ref={nextDesktopRef}>
                            <IoIosArrowForward className="text-dark rounded-circle bg-branco-transparente" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    <Link to="/profissionais" className="d-none d-sm-block align-self-center text-danger-emphasis fw-semibold rounded-5 py-3 px-5 link-underline link-underline-opacity-0 btn-agendar-consulta">
                        Agende agora sua consulta
                    </Link>
                </div>
            </div>

            <div className="min-vh-60 d-flex flex-column justify-content-center align-items-center mt-4 mt-sm-5 gap-5">
                <h2 className="fw-bold display-5 text-center">Como funciona?</h2>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-column flex-wrap flex-md-row align-items-center align-items-md-start justify-content-center col-12 px-4 gap-0 gap-lg-3 gap-xl-0 gap-xxl-4">
                        <div className="d-flex flex-column align-items-center col-12 col-sm-11 col-md-6 col-lg-5 col-xl-3 col-xxl-2">
                            <Bs1Circle className="align-self-start fs-2" />
                            <MdComputer
                                title="Ilustração de um notebook"
                                size={tamanhoIcone} />
                            <div className="fs-6 fw-semibold mt-3 text-center">Acesse o site <span className="text-danger-emphasis">falarsalva.com.br</span> e conheça os profissionais</div>
                        </div>
                        <div className="d-flex flex-column align-items-center col-12 col-sm-11 col-md-6 col-lg-5 col-xl-3 col-xxl-2">
                            <Bs2Circle className="align-self-start fs-2" />
                            <AiOutlineMessage
                                title="Ilustração de um balão de conversa"
                                size={tamanhoIcone} />
                            <div className="fs-6 fw-semibold mt-3 text-center">Clique em <span className="text-danger-emphasis">agendar sessão</span> e encaminhe a sua mensagem para o psicólogo que deseja realizar o atendimento</div>
                        </div>
                        <div className="d-flex flex-column align-items-center col-12 col-sm-11 col-md-6 col-lg-5 col-xl-3 col-xxl-2">
                            <Bs3Circle className="align-self-start fs-2" />
                            <HiOutlineClipboardDocumentList
                                title="Ilustração de uma prancheta com um documento"
                                size={tamanhoIcone} />
                            <div className="fs-6 fw-semibold mt-3 text-center ">O psicólogo irá te encaminhar um pequeno formulário para saber mais sobre você, e em seguida já irá explicar os detalhes do agendamento</div>
                        </div>
                        <div className="d-flex flex-column align-items-center col-12 col-sm-11 col-md-6 col-lg-5 col-xl-3 col-xxl-2">
                            <Bs4Circle className="align-self-start fs-2" />
                            <FaUserFriends
                                title="Ilustração simplificada de duas pessoas"
                                size={tamanhoIcone} />
                            <div className="fs-6 fw-semibold mt-3 text-center">Através da primeira consulta, o psicólogo irá avaliar os seus niveis de ansiedade e tristeza, para que possam fazer um comparativo entre o inicio e o final do tratamento</div>
                        </div>
                    </div>
                    {/* <div className="d-flex flex-column mt-4 mb-4 mb-sm-0 mt-sm-5 text-center">
                        <Link to="/profissionais" className="btn btn-lg btn-outline-danger-emphasis text-white rounded-5 link-offset-2 link-underline link-underline-opacity-0 mx-2 mx-sm-0">Conheça os profissionais do projeto</Link>
                    </div> */}
                </div>
            </div>
            <div className="text-center mt-4 mb-5">
                <Link to="/profissionais" className="btn btn-lg btn-profissionais fw-semibold text-danger-emphasis rounded-5 link-offset-2 link-underline link-underline-opacity-0">Conheça os profissionais do projeto</Link>
            </div>
            <CustomCarousel />

            <div className="d-flex">
                <div className="d-flex flex-column flex-xl-row justify-content-center align-items-center px-md-5 bg-dados-terapia py-5 min-vh-70">
                    <div className="col-8 col-sm-6 col-lg-4 col-xxl-3 me-xl-5">
                        <img src={ilustracaoTerapia} alt="Ilustração de duas mulheres conversando, uma delas sentada num divã e a outra numa poltrona." className="img-fluid"></img>
                    </div>
                    <div className="fs-5 col-11 col-sm-10 col-xl-8">
                        <p className="fw-bold">Descubra a importância da terapia</p>

                        <p>A saúde mental é uma parte essencial de uma vida equilibrada, e muitas pessoas em todo o mundo enfrentam desafios relacionados a transtornos mentais. Dados da Organização Mundial da Saúde (OMS) revelam que aproximadamente 10% da população global recebeu um diagnóstico de transtorno mental.</p>

                        <p>No Brasil, não é diferente. Estatísticas recentes apontam que quase 60 milhões de brasileiros enfrentam transtornos mentais diagnosticados. No entanto, apenas 3% da população busca ativamente a terapia.</p>

                        <p>Nós acreditamos que o apoio psicológico é fundamental para uma vida saudável e equilibrada. Por isso, o projeto Falar Salva está aqui para conectar você com profissionais de saúde mental qualificados que podem ajudar, tudo isso no conforto da sua casa.</p>

                        <p>É hora de priorizar sua saúde mental e dar o primeiro passo em direção a uma vida mais feliz e equilibrada. Comece sua jornada de cuidado com a saúde mental hoje mesmo!</p>
                    </div>
                </div>
            </div>

            <div className="d-flex bg-sobre py-5">
                <div className='fs-4 text-bege-claro fw-medium d-flex flex-fill flex-column align-items-center container'>
                    <div className="col-12 col-md-9 col-xxl-7">
                        <div className="d-flex flex-column justify-content-center justify-content-sm-start">
                            <h2 className="display-5 fw-bold mt-5">Sobre nós</h2>
                            <h3 className="fw-bold fs-4 mb-0">Um pouco mais sobre o projeto que veio para transformar vidas</h3>
                        </div>
                        <p className='text-bege-claro mt-5'>
                            O projeto Falar Salva tem como o objetivo proporcionar
                            atendimento psicólogico online de acordo com as
                            condições socioeconômicas de cada paciente,
                            independente de onde estiver.
                        </p>
                        <p className='text-bege-claro'>
                            Nós acreditamos que a Terapia é para todos aqueles
                            que estejam dispostos a enfrentarem as suas
                            batalhas internas e com ajuda especializada.
                        </p>
                        <p className='text-bege-claro'>
                            Nossos profissionais são especializados em diversas
                            demandas como Ansiedade, Depressão, TDAH,
                            Transtornos de Humor e de Personalidade, e várias
                            outras queixas com a finalidade de proporcionarmos
                            o melhor atendimento.
                        </p>
                        <p className='mb-4 text-bege-claro'>
                            Nosso objetivo é ensinar o paciente a lidar com as
                            suas batalhas, utilizando ferramentas baseadas em
                            estudos científicos, e não depender de psicoterapia
                            para sempre, como uma muleta.
                        </p>
                    </div>
                </div>
            </div>
            <Disclaimer />
            <div className="footer">
                <Prefooter />
                <Footer />
            </div>
        </>
    )
}